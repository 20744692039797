import { InputAdornment, TextField } from '@mui/material';
import React, { useRef, useEffect } from 'react';

interface NumberInputProps {
  label: string
  value: number | null
  step: number
  className?: string;
  id?: string;
  placeholder?: string;
  onInput: (value: number | null) => void
}

function fixJSrounding(x: number) {
  return Number(x.toFixed(10));
}

function NumberInput(props: NumberInputProps) {

  const inputEl = useRef<HTMLInputElement>();

  useEffect(() => {
    (inputEl.current as any).testinput = (val: number) => props.onInput(val);
  })

  return (
    <TextField
      variant="standard"
      size="small"
      label={props.label}
      type="number"
      //InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
      inputRef={inputEl}
      value={props.value ?? ""}
      className={props.className}
      id={props.id}
      placeholder={props.placeholder}
      InputLabelProps={{shrink: true}}
      onChange={(ev) => {
        const plainVal = ev.target.value;
        if (plainVal == "") {
          props.onInput(null);
          return;
        }
        let val = Number(plainVal);
        const nev = ev.nativeEvent as InputEvent
        if (props.step != null && (nev.inputType == 'insertReplacementText' || nev.inputType === undefined)) { // Chrome has inputType=undefined
          let p = props.value ?? 0
          if (p < val) { // step up
            val = fixJSrounding(p + props.step);
          } else { // step down
            val = fixJSrounding(p - props.step);
          }
        }
        props.onInput(val);
      }}
    />
  );
}

export default NumberInput;
