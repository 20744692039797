function getApiUrlNonCached(): string {

  var hostName = window.location.hostname;

  switch (hostName) {
    default:
    case "rfrcalculator.dev.finapps.ihsmarkit.com":
      return "https://api-rfrcalculator.dev.finapps.ihsmarkit.com";
    case "vnext-rfrcalculator.dev.finapps.ihsmarkit.com":
      return "https://vnext-api-rfrcalculator.dev.finapps.ihsmarkit.com";
    case "rfrcalculator.qa.finapps.ihsmarkit.com":
      return "https://api-rfrcalculator.qa.finapps.ihsmarkit.com";
    case "vnext-rfrcalculator.qa.finapps.ihsmarkit.com":
      return "https://vnext-api-rfrcalculator.qa.finapps.ihsmarkit.com";
    case "rfrcalculator.uat.finapps.ihsmarkit.com":
      return "https://api-rfrcalculator.uat.finapps.ihsmarkit.com";
    case "vnext-rfrcalculator.uat.finapps.ihsmarkit.com":
      return "https://vnext-api-rfrcalculator.uat.finapps.ihsmarkit.com";
    case "rfrcalculator.finapps.ihsmarkit.com":
      return "https://api-rfrcalculator.finapps.ihsmarkit.com";
    case "vnext-rfrcalculator.finapps.ihsmarkit.com":
      return "https://vnext-api-rfrcalculator.finapps.ihsmarkit.com";

    case "localhost":
      return "https://localhost:44350"
  }
}

let apiUrl: string;

export function getApiUrl(): string {
  if (!apiUrl) {
    apiUrl = getApiUrlNonCached();
  }
  return apiUrl;
}

