import React from 'react';
import { InputModel, ResultsModel, ValidationInputs } from './models/InputModel';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { getResultTable, getResultTotals } from './data/FormatResults';
import { lookupRFRvalue } from './data/AllInputOptions';

interface DisclaimersProps {
  inputs: InputModel;
  validationInputs: ValidationInputs;
  results: ResultsModel | null;
}

function RateDisclaimers({ inputs }: { inputs: InputModel }) {

  switch (inputs.RFR) {
    case "SOFR":
      if (true) // check tenor
      {
        return (
          <span>
            The SOFR benchmark is published on the Federal Reserve Bank of New York's{' '}
            <a id="sofrBankWebsite" href="https://apps.newyorkfed.org/markets/autorates/sofr" target="_blank" rel="noopener noreferrer">website</a>{' '}
            at 8:00 am EST each business day.
            For further details on the calculation of the SOFR rate please see the
            Federal Reserve Bank of New York's methodology{' '}
            <a id="sofrMethodology" href="https://www.newyorkfed.org/markets/treasury-repo-reference-rates-information" target="_blank" rel="noopener noreferrer">here</a>.
            Use of SOFR rates is subject to the Fed's{' '}
            <a id="sofrTermsOfUse" href="https://www.newyorkfed.org/markets/reference-rates-terms-of-use" target="_blank" rel="noopener noreferrer">Terms of Use</a>.
          </span>
        )
      } else {
        return (
          <span>
            The SOFR Historical Term Rates are published on CME Group{' '}
            <a id="cmeWebsite" href="https://www.cmegroup.com/market-data/cme-group-benchmark-administration/term-sofr.html" target="_blank" rel="noopener noreferrer">website</a>.
            For further details on the calculation of the CME Term SOFR rates please see the CME Group's methodology{' '}
            <a id="termSofrMethodology" href="https://www.cmegroup.com/market-data/files/cme-term-sofr-reference-rates-benchmark-methodology.pdf" target="_blank" rel="noopener noreferrer">here</a>.
          </span>
        )
      }

    case "SONIA":
      return (
        <span>
          The SONIA benchmark is published on the Bank of England's{' '}
          <a id="soniaHistorical" href="https://www.bankofengland.co.uk/boeapps/iadb/fromshowcolumns.asp?html.x=yes&Datefrom=01/Jan/2011&Dateto=NOW%20&SeriesCodes=IUDSOIA&UsingCodes=Y&VPD=Y&VFD=N" target="_blank" rel="noopener noreferrer">historical database</a>{' '}
          at 10:00 am GMT on the business day after it is first published.
          For further details on the calculation of the SONIA rate please see the Bank of England's methodology{' '}
          <a id="soniaMethodology" href="https://www.bankofengland.co.uk/statistics/details/further-details-about-wholesale-interbank-sterling-market-data" target="_blank" rel="noopener noreferrer">here</a>.
        </span>
      )

    case "ESTR":
      return (
        <span>
          The ESTR benchmark is published on the European Central Bank's{' '}
          <a id="estrBankWebsite" href="https://www.ecb.europa.eu/stats/financial_markets_and_interest_rates/euro_short-term_rate/html/index.en.html" target="_blank" rel="noopener noreferrer">website</a>{' '}
          at 8:00 am CET each business day.
          For further details on the calculation of the ESTR rate please see the European Central Bank's methodology{' '}
          <a id="estrMethodology" href="https://www.ecb.europa.eu/paym/initiatives/interest_rate_benchmarks/shared/pdf/ecb.ESTER_methodology_and_policies.en.pdf" target="_blank" rel="noopener noreferrer">here</a>.
          Use of ESTR rates is subject to the ECB's{' '}
          <a id="estrDisclaimer" href="https://www.ecb.europa.eu/stats/financial_markets_and_interest_rates/euro_short-term_rate/html/index.en.html#disclaimer" target="_blank" rel="noopener noreferrer">disclaimer</a>{' '}
          and{' '}
          <a id="estrCopyright" href="https://www.ecb.europa.eu/home/disclaimer/html/index.en.html" target="_blank" rel="noopener noreferrer">copyright statement</a>.
        </span>
      )

    case "TONAR":
      return (
        <span>
          The TONAR benchmark is published on the Bank of Japan's{' '}
          <a id="tonarBankWebsite" href="https://www.stat-search.boj.or.jp/ssi/mtshtml/fm01_d_1_en.html" target="_blank" rel="noopener noreferrer">historical database</a>{' '}
          at 10:00 am JST on the business day after it is first published.
          For further details on the calculation of the TONAR please see the Bank of Japan's methodology{' '}
          <a id="tonarMethodology" href="https://www.boj.or.jp/en/statistics/outline/exp/exmenu_m.pdf" target="_blank" rel="noopener noreferrer">here</a>.
          Use of TONAR is subject to the BOJ's{' '}
          <a id="tonarDisclaimer" href="https://www.boj.or.jp/en/statistics/outline/exp/data/exmutan2.pdf" target="_blank" rel="noopener noreferrer">Terms of Use</a>.
        </span>
      )

    case "SORA":
      return (
        <span>
          The SORA benchmark is published by MAS and reflects the volume-weighted average rate of all SGD overnight cash transactions brokered in Singapore between 9:00 am to 6:15 pm.
          It is published daily on the MAS{' '}
          <a id="soraBankWebsite" href="https://eservices.mas.gov.sg/Statistics/dir/DomesticInterestRates.aspx" target="_blank" rel="noopener noreferrer">website</a>{' '}
          at around 6:30 SGT.
        </span>
      )

    case "SARON":
      return (
        <span>
          SIX is the Benchmark Administrator of SARON and is responsible for its calculation and publication.
          Further information on licensing, data access and usage can be found{' '}
          <a id="saronBankWebsite" href="https://www.six-group.com/exchanges/indices/data_centre/swiss_reference_rates/reference_rates_en.html" target="_blank" rel="noopener noreferrer">here</a>.
        </span>
      )

    case "CORRA":
      return (
        <span>
          The CORRA benchmark is published on the Bank of Canada's{' '}
          <a id="corraBankWebsite" href="https://www.bankofcanada.ca/rates/interest-rates/money-market-yields/#table" target="_blank" rel="noopener noreferrer">website</a>{' '}
          between 9:00 - 11:00 am EST each business day.
          For further details on the calculation of the CORRA rate please see the Bank of Canada's methodology{' '}
          <a id="corraMethodology" href="https://www.bankofcanada.ca/rates/interest-rates/corra/" target="_blank" rel="noopener noreferrer">here</a>.
        </span>
      )
  }
}

function Disclaimers({ inputs, validationInputs, results }: DisclaimersProps) {

  const rfr = lookupRFRvalue(inputs.RFR);

  return (
    <>
      <section className="disclaimer rateDisclaimer">
        <RateDisclaimers inputs={inputs} />
      </section>
      {validationInputs.mostRecentPublishedDate ?
        <section className="disclaimer rateDisclaimer">
          <span>
            The most recent published rate from the {inputs.interestMethod == "TermCME" ? "CME" : rfr.publisher} is {validationInputs.mostRecentPublishedDate}.
          </span>
        </section>
        : <></>
      }
      {results && results.inputs.commitment.some(c => c.change != 0) ? <></> :
        <section className="disclaimer rateDisclaimer">
          <span>
            With no commitment changes in the interest period, Earned Interest Amount = Commitment x Period Compounded All-In Rate.
          </span>
        </section>
      }
    </>
  )

}

export default Disclaimers;
