export function displayLoading() {
  document.querySelectorAll('#loading').forEach(x => x.remove());

  const container = document.createElement('div');
  container.id = "loading";
  container.innerHTML = "LOADING...";
  document.body.appendChild(container);
}

export function hideLoading() {
  document.querySelectorAll('#loading').forEach(x => x.remove());
}
