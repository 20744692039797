export interface AccrualResult {
  NotionalAmount: number;
  NotionalAmountCompounded: number;
  StartDate: string;
  EndDate: string;
  BaseRatePublished?: number;
  BaseRate: number;
  Spread: number;
  Adjustment?: number;
  AnnualizedCompoundedAllInRate?: number;
  EarnedInterestFromBaseRate: number;
  EarnedInterestFromSpread: number;
  EarnedInterestFromAdjustment?: number;
  EarnedInterest: number;
  EarnedInterestRunningBalance: number;
  AccruedInterestBalance?: number;
  DayCount?: number;
  EffectiveRFR?: number;
  CompoundingFactor?: number;
  AnnualizedCCR?: number;
  UnannualizedCCR?: number;
  NonCCR?: number;
}

export interface CalcResponse {
  AccrualResults: AccrualResult[];
  InterestReceived: number[];
  TotalInterest: number;
  AccruedAndUnpaidInterest?: number;
  TotalInterestReceived?: number;
  TotalCompoundBalanceTransferred?: number;
  AnnualizedCompoundedAllInRate?: number;
  PeriodCompoundedAllInRate?: number;
}

export interface APIReponseBase {
  ApiVersion: number;
}

export interface APIResponse extends APIReponseBase {
  CalcRequest: object;
  CalcResponse: CalcResponse;
}

export interface APIDateRangeResponse extends APIReponseBase {
  MinStartDate: string;
  MaxEndDate: string;
  LastPublishedDate: string;
}

export interface APIBusinessDayCountResponse extends APIReponseBase {
  BusinessDayCount: number;
}

export interface APIHolidayDatesResponse extends APIReponseBase {
  HolidayDates: string[];
}

export interface ErrorResponse extends APIReponseBase {
  Error: string;
}

export function isErrorResponse(result: object): result is ErrorResponse {
  return (result as ErrorResponse).Error !== undefined;
}
