import { InputAdornment, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

interface FormattedNumberInputProps {
  label: string
  value: number
  step: number
  className?: string;
  id?: string;
  onInput: (value: number) => void
  locale: string
  currency: string
}

function fixJSrounding(x: number) {
  return Number(x.toFixed(10));
}

function FormattedNumberInput(props: FormattedNumberInputProps) {
  const [focused, setFocused] = useState(false);

  const inputEl = useRef<HTMLInputElement>();

  useEffect(() => {
    (inputEl.current as any).testinput = (val: number) => props.onInput(val);
  })

  function numberToString(n: number, digits: number = 2) {
    return n.toLocaleString(props.locale, { minimumFractionDigits: digits, maximumFractionDigits: digits })
  }

  return (
    <TextField
      variant="standard"
      size="small"
      label={props.label}
      type={focused ? "number" : "text"}
      InputProps={{ startAdornment: <InputAdornment position="start">{props.currency}</InputAdornment> }}
      inputRef={inputEl}
      value={focused ? props.value : numberToString(props.value)}
      className={props.className}
      id={props.id}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      onChange={(ev) => {
        let val = Number(ev.target.value);
        const nev = ev.nativeEvent as InputEvent
        if (props.step != null && (nev.inputType == 'insertReplacementText' || nev.inputType === undefined)) { // Chrome has inputType=undefined
          let p = props.value
          if (p < val) { // step up
            val = fixJSrounding(p + props.step);
          } else { // step down
            val = fixJSrounding(p - props.step);
          }
        }
        props.onInput(val);
      }}
    />
  );
}

export default FormattedNumberInput;
