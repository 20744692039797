import { Button, Menu, MenuItem } from '@mui/material';
import { ReactNode, useState } from 'react';

interface MyButtonProps {
  name: string;
  buttonContent: ReactNode;
  items: { key: string, label: string, onClick(): void }[]
}

function MyMenu(props: MyButtonProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <Button
        id={props.name + '-button'}
        aria-controls={open ? props.name + '-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        color="secondary"
      >
        {props.buttonContent}
      </Button>
      <Menu
        id={props.name + '-menu'}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': props.name + '-button',
        }}
      >
        {props.items.map(i => <MenuItem key={i.key} onClick={() => { handleClose(); i.onClick(); }}>{i.label}</MenuItem>)}
      </Menu>
    </>
  );
}

export default MyMenu;
