import { ResultsModel } from "../models/InputModel";
import { getResultTable } from "./FormatResults";
import { saveAs } from 'file-saver';
import * as xlsx from 'xlsx';
import { lookupRFRvalue } from "./AllInputOptions";

function quoteStringWithComma(x: string) {
  if (x.includes(',')) {
    return '"' + x + '"';
  }
  return x;
}

function getDataTable(result: ResultsModel, isXlsx: false): string[][];
function getDataTable(result: ResultsModel, isXlsx: true): (string | Date | number)[][];
function getDataTable(result: ResultsModel, isXlsx: boolean) {
  const [tableHeader, tableData] = getResultTable(result, isXlsx);
  return [tableHeader.map(h => h.label), ...tableData];
}

export function downloadCSV(result: ResultsModel) {
  const csv = getDataTable(result, false).map(r => r.map(quoteStringWithComma).join(',')).join('\r\n');
  const blob = new Blob([csv], { type: 'text/csv' });
  saveAs(blob, "SummaryOfRates.csv");
}

export function downloadXLSX(result: ResultsModel) {
  const workBook = xlsx.utils.book_new();
  const dataAsStrings = getDataTable(result, false);
  const rfr = lookupRFRvalue(result.inputs.RFR);
  const workSheet = xlsx.utils.aoa_to_sheet(getDataTable(result, true), { dateNF: rfr.dateFormat });
  workSheet["!cols"] = dataAsStrings[0].map((_, i) => ({ wch: Math.max(...dataAsStrings.map(row => row[i].length)) }));
  xlsx.utils.book_append_sheet(workBook, workSheet, 'Sheet1');
  xlsx.writeFile(workBook, "SummaryOfRates.xlsx");
}
