import { Checkbox, FormControlLabel } from '@mui/material';

interface MyCheckboxProps {
  checked: boolean;
  label: string;
  isSecondary?: boolean;
  className?: string;
  id?: string;
  onChange: (value: boolean) => void;
}

function MyCheckbox(props: MyCheckboxProps) {
  return (
    <FormControlLabel
      className={props.className}
      control={
        <Checkbox
          color={props.isSecondary ? 'secondary' : 'primary'}
          id={props.id}
          checked={props.checked}
          onChange={(e) => props.onChange(e.target.checked)}
        />}
      label={props.label}
    />
  );
}

export default MyCheckbox;
