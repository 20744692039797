import { DayCount, FloorType, FloorTypeName, Index, InterestMethod } from "../models/InputModel";
import { Calendar, DayCountValue, RFRValue } from "../models/UIRequestModel";

export const lookBacks: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const lockouts: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const roundingValues: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

export const allFloorTypeValues: FloorType[] = [
  { name: "Base Rate", shortName: "BR", allInRate: false, inclAdj: false },
  { name: "Base Rate Incl. Adj.", shortName: "BRIA", allInRate: false, inclAdj: true },
  { name: "All-In Rate", shortName: "AIR", allInRate: true, inclAdj: false },
  { name: "All-In Rate Incl. Adj.", shortName: "AIRIA", allInRate: true, inclAdj: true }
];

export function lookupFloorType(ft: FloorTypeName): FloorType { 
  return allFloorTypeValues.find(f => f.name == ft) as FloorType;
}

export const dayCountValues: DayCount[] = [
  { name: "ACT/365", v1: "ACT365", v2: 365 },
  { name: "ACT/360", v1: "ACT360", v2: 360 },
  { name: "ACT/ACT", v1: "ACTACT", v2: 1 }
];

export function lookupDayCount(dc: DayCountValue): DayCount {
  return dayCountValues.find(c => c.v1 == dc) as DayCount;
}

export const calendars: Calendar[] = ["USA", "USA - Good Friday", "UK", "EUR", "JP", "SG", "CH", "CA"];

export const RFRvalues: Index[] = [
  { name: "SOFR", cur: '$', defaultCalendar: "USA", defaultDayCount: "ACT360", publisher: "FRBNY", locale: 'en-US', dateFormat: 'MM/DD/YYYY', rounding: 2 },
  { name: "SONIA", cur: '£', defaultCalendar: "UK", defaultDayCount: "ACT365", publisher: "BOE", locale: 'en-GB', dateFormat: 'DD/MM/YYYY', rounding: 4 },
  { name: "ESTR", cur: '€', defaultCalendar: "EUR", defaultDayCount: "ACT360", publisher: "ECB", locale: 'en-DE', dateFormat: 'DD/MM/YYYY', rounding: 3 },
  { name: "TONAR", cur: '¥', defaultCalendar: "JP", defaultDayCount: "ACT365", publisher: "BOJ", locale: 'en-GB', dateFormat: 'DD/MM/YYYY', rounding: 3 },
  { name: "SORA", cur: 'S$', defaultCalendar: "SG", defaultDayCount: "ACT365", publisher: "MAS", locale: 'en-GB', dateFormat: 'DD/MM/YYYY', rounding: 4 },
  { name: "SARON", cur: 'fr.', defaultCalendar: "CH", defaultDayCount: "ACT360", publisher: "SIX", locale: 'en-GB', dateFormat: 'DD/MM/YYYY', rounding: 6 },
  { name: "CORRA", cur: 'C$', defaultCalendar: "CA", defaultDayCount: "ACT365", publisher: "BOC", locale: 'en-GB', dateFormat: 'DD/MM/YYYY', rounding: 2 },
];

export function lookupRFRvalue(rfr: RFRValue): Index {
  return RFRvalues.find(r => r.name == rfr) as Index;
}

export const allInterestMethodValues: InterestMethod[] = [
  { name: "Compounding in Arrears", value: "CompoundInArrears" },
  { name: "Compounding - NCCR", value: "NCCR" },
  { name: "Simple in Arrears", value: "SimpleInArrears" },
  { name: "Term", value: "Term" },
  //{ name: "Term (CME)", value: "TermCME" }
];

export function lookupInterestMethod(im: string): InterestMethod {
  return allInterestMethodValues.find(i => i.name == im) as InterestMethod;
}
