import { utc } from "moment";
import { invalid } from "moment";
import { InputAction } from "../models/InputAction";
import { CommitmentChange, defaultInputModel, InputModel, ValidationInputs } from "../models/InputModel";
import { doCalculate } from "../services/CalcService";
import { allFloorTypeValues, lookupRFRvalue } from "./AllInputOptions";
import { dataDateFormat, isValidDate } from "./DateUtils";
import { getFloorTypeOptions, isNoSpread } from "./InputAvailability";

export function inputReducer(input: InputModel, action: InputAction): InputModel {
  const newInput: InputModel = { ...input };

  function cloneCommitments() {
    newInput.commitment = newInput.commitment.map(c => ({ ...c }));
  }

  switch (action.type) {
    case "commitment":
      cloneCommitments();
      const current = newInput.commitment[action.index];
      const ccAction = action.value;
      switch (ccAction.type) {
        case "effectiveDate":
          current.effectiveDate = ccAction.value;
          break;

        case "spreadChange":
          current.spreadChange = ccAction.value;
          updateSpreadChange(newInput.commitment, action.index)
          fixFloorType(newInput);
          break;

        case "spread":
          current.spread = ccAction.value;
          updateSpread(newInput.commitment, action.index)
          fixFloorType(newInput);
          break;

        case "change":
          current.change = ccAction.value;
          updateCommitmentChange(newInput.commitment, action.index)
          break;

        case "notional":
          current.notional = ccAction.value;
          updateCommitment(newInput.commitment, action.index)
          break;

        case "balanceTransfer":
          current.balanceTransfer = ccAction.value;
          if (ccAction.value) current.interestReceived = false;
          break;

        case "interestReceived":
          current.interestReceived = ccAction.value;
          if (ccAction.value) current.balanceTransfer = false;
          break;

        case "addCommitment":
          addCommitmentChange(newInput, action.index, ccAction.validationInputs);
          break;

        case "removeCommitment":
          removeCommitmentChange(newInput, action.index);
          break;
      }
      break;

    case "addCommitment":
      newInput.commitment = [...newInput.commitment];
      addCommitmentChange(newInput, input.commitment.length - 1, action.validationInputs);
      break;

    case "endDate":
      newInput.endDate = action.value;
      break;

    case "lookBack":
      newInput.lookBack = action.value;
      break;

    case "observationShift":
      newInput.observationShift = action.value;
      break;

    case "lockout":
      newInput.lockout = action.value;
      break;

    case "selectedCountryForHolidays":
      newInput.selectedCountryForHolidays = action.value;
      break;

    case "interestMethod":
      newInput.interestMethod = action.value;
      break;

    case "RFR":
      const rfr = lookupRFRvalue(action.value);
      newInput.RFR = action.value;
      newInput.selectedCountryForHolidays = [rfr.defaultCalendar];
      newInput.dayCount = rfr.defaultDayCount;
      // change Start Date for CORRA to not be on holiday
      if (action.value === "CORRA" && newInput.commitment[0].effectiveDate == "09/30/2022") {
        cloneCommitments();
        newInput.commitment[0].effectiveDate = "09/29/2022";
      }
      break;

    case "dayCount":
      newInput.dayCount = action.value;
      break;

    case "rateRounding":
      newInput.rateRounding = action.value;
      break;

    case "adjustment":
      newInput.adjustment = action.value;
      fixFloorType(newInput);
      break;

    case "termRate":
      newInput.termRate = action.value;
      break;

    case "floor":
      newInput.floor = action.value;
      fixFloorType(newInput);
      break;

    case "floorType":
      newInput.floorType = action.value;
      break;

    case "calculate":
      action.controller.current?.abort();
      const controller = new AbortController();
      action.controller.current = controller;
      doCalculate(input, action.setResults, action.setApiErrorMessage, controller);
      return input;

    case "reset":
      action.setResults(null);
      return defaultInputModel();
  }

  return newInput;
}

function fixFloorType(inputs: InputModel) {
  const newFloorTypeOptions = getFloorTypeOptions(inputs);

  const hasAllInRate = !isNoSpread(inputs);
  const hasInclAdj = inputs.adjustment != null && inputs.adjustment != 0;

  if (inputs.floorType === null) {
    inputs.floorType = newFloorTypeOptions.find(ft =>
      !ft.allInRate && (ft.inclAdj == hasInclAdj)
    )?.name ?? null;
  } else if (!newFloorTypeOptions.some(ft => inputs.floorType == ft.name)) {
    const prevFloorType = allFloorTypeValues.find(ft => inputs.floorType == ft.name)
    inputs.floorType = newFloorTypeOptions.find(ft =>
      ft.allInRate == (hasAllInRate && prevFloorType?.allInRate)
      && ft.inclAdj == (hasInclAdj && prevFloorType?.inclAdj)
    )?.name ?? null;
  }
}

function addCommitmentChange(inputs: InputModel, i: number, validationInputs: ValidationInputs) {
  const prev = inputs.commitment[i];
  let nextDate = utc(prev.effectiveDate, dataDateFormat);

  do {
    nextDate.add(1, 'd');
  } while (!isValidDate(inputs, validationInputs, nextDate));

  if (nextDate.isAfter(validationInputs.maxEffectiveDate)) {
    nextDate = invalid();
  }
  inputs.commitment.splice(i + 1, 0,
    {
      effectiveDate: nextDate.format(dataDateFormat),
      spread: prev.spread,
      spreadChange: 0,
      notional: prev.notional,
      change: 0,
      balanceTransfer: true,
      interestReceived: false
    }
  );
}

function fixJSrounding(x: number) {
  return Number(x.toFixed(10));
}

function removeCommitmentChange(inputs: InputModel, i: number) {
  if (i > 0 && i < inputs.commitment.length - 1) {
    const prev = inputs.commitment[i - 1];
    const next = inputs.commitment[i + 1];
    next.change = next.notional - prev.notional;
    next.spreadChange = fixJSrounding(next.spread - prev.spread);
  }
  inputs.commitment.splice(i, 1);
}

function updateSpread(commitment: CommitmentChange[], i: number, keepChanges?: boolean) {
  const current = commitment[i];
  if (i > 0) {
    const prev = commitment[i - 1];
    current.spreadChange = fixJSrounding(current.spread - prev.spread);
  }
  if (i < commitment.length - 1) {
    const next = commitment[i + 1];
    if (keepChanges) {
      next.spread = fixJSrounding(current.spread + next.spreadChange);
      updateSpread(commitment, i + 1, true);
    } else {
      next.spreadChange = fixJSrounding(next.spread - current.spread);
    }
  }
}

function updateSpreadChange(commitment: CommitmentChange[], i: number) {
  const current = commitment[i];
  if (i > 0) {
    const prev = commitment[i - 1];
    current.spread = fixJSrounding(prev.spread + current.spreadChange);
  }
  if (i < commitment.length - 1) {
    const next = commitment[i + 1];
    next.spread = fixJSrounding(current.spread + next.spreadChange);
    updateSpread(commitment, i + 1, true);
  }
}

function updateCommitment(commitment: CommitmentChange[], i: number, keepChanges ?: boolean) {
  const current = commitment[i];
  if (i > 0) {
    const prev = commitment[i - 1];
    current.change = current.notional - prev.notional;
  }
  if (i < commitment.length - 1) {
    const next = commitment[i + 1];
    if (keepChanges) {
      next.notional = current.notional + next.change;
      updateCommitment(commitment, i + 1, true);
    } else {
      next.change = next.notional - current.notional;
    }
  }
}

function updateCommitmentChange(commitment: CommitmentChange[], i: number) {
  const current = commitment[i];
  if (i > 0) {
    const prev = commitment[i - 1];
    current.notional = prev.notional + current.change;
  }
  if (i < commitment.length - 1) {
    const next = commitment[i + 1];
    next.notional = current.notional + next.change;
    updateCommitment(commitment, i + 1, true);
  }
}
