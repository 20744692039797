import { FormControl, InputLabel, Select, TextField } from '@mui/material';
import { ReactNode } from 'react';

interface MySelectProps<TValue> {
  label: string;
  value: TValue;
  className?: string;
  id?: string;
  menuClassName?: string;
  renderValue?: (value: TValue) => any;
  onChange: (value: TValue) => void
  children: ReactNode;
  multiple?: boolean;
  disabled?: boolean;
}

function MySelect<TValue>(props: MySelectProps<TValue>) {
  return (
    <TextField
      variant="standard"
      size="small"
      select
      label={props.label}
      disabled={props.disabled}
      value={props.value}
      InputLabelProps={{ shrink: true }}
      SelectProps={{
        multiple: props.multiple,
        disabled: props.disabled,
        renderValue: props.renderValue as any,
        MenuProps:{
          className: props.menuClassName
        }
      }}
      onChange={
        (r) => {
          const v = r.target.value as TValue;
          if (v != null) { props.onChange(v); }
        }
      }
      className={props.className}
      id={props.id}
    >
      {props.children}
    </TextField>
  );
}

export default MySelect;
