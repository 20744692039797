import { Moment } from "moment";
import { InputModel, ValidationInputs } from "../models/InputModel";

export const dataDateFormat = "MM/DD/YYYY";

export function isValidDate(inputs: InputModel, validationInputs: ValidationInputs, d: Moment): boolean {
  return inputs.interestMethod === "SimpleInArrears" || inputs.interestMethod === "Term"
    || isBusinessDay(validationInputs, d);
}

export function isBusinessDay(validationInputs: ValidationInputs, d: Moment): boolean {
  return (d.isoWeekday() < 6 && !validationInputs.holidayDates.some(h => h.isSame(d)));
}
