import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ReactMarkdown from 'react-markdown';

interface MarkdownDialogProps {
  title: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  sourceUrl: string;
}

export default function MarkdownDialog(props: MarkdownDialogProps) {
  const [md, setMd] = useState("Loading...")

  useEffect(() => {
    fetch(props.sourceUrl)
      .then((response) => response.text())
      .then((text) => setMd(text))
  }, [props.sourceUrl]);

  function handleClose() {
    props.setOpen(false);
  }

  return (
    <div>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="markdown-dialog-title"
        aria-describedby="markdown-dialog-description"
      >
        <DialogTitle id="markdown-dialog-title">
          {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="markdown-dialog-description">
            <ReactMarkdown>{md}</ReactMarkdown>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
