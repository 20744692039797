import { DesktopDatePicker } from '@mui/x-date-pickers';
import { Moment } from 'moment';

interface DateInputProps {
  label: string
  value: Moment | null
  className?: string;
  id: string;
  dateFormat: string;
  minDate?: Moment
  maxDate?: Moment
  shouldDisableDate: (day: Moment) => boolean
  onChange: (value: (Moment | null)) => void
}

function DateInput(props: DateInputProps) {
  return (
    <DesktopDatePicker
      key={props.id + "-" + props.dateFormat}
      slotProps={{
        textField: { variant: 'standard', size: 'small', id: props.id },
        openPickerButton: { id: props.id + "-openpicker" }
      }}
      label={props.label}
      value={props.value}
      className={props.className}
      minDate={props.minDate}
      maxDate={props.maxDate}
      shouldDisableDate={props.shouldDisableDate}
      onChange={props.onChange}
      format={props.dateFormat}
    />
  );
}

export default DateInput;
