import { Button } from '@mui/material';
import { ReactNode } from 'react';

interface MyButtonProps {
  disabled?: boolean;
  isSecondary?: boolean;
  id?: string;
  onClick: () => void;
  children: ReactNode;
}

function MyButton(props: MyButtonProps) {
  return (
    <Button
      variant="outlined"
      disabled={props.disabled}
      id={props.id}
      color={props.isSecondary ? 'secondary' : 'primary'}
      onClick={props.onClick}
    >{props.children}</Button>
  );
}

export default MyButton;
