import { utc } from "moment";
import { ResultsModel } from "../models/InputModel";
import { lookupRFRvalue } from "./AllInputOptions";

interface ResultTotal {
  label: string;
  key: string;
  value: string;
}

export function getResultTotals(results: ResultsModel): ResultTotal[] {

  if (results.inputs == null) {
    return [];
  }

  const rfr = lookupRFRvalue(results.inputs.RFR);
  const locale = rfr.locale;

  const totals: ResultTotal[] = []

  function numberToString(n: number, digits: number = 2) {
    if (n == null) {
      return "";
    }
    return n.toLocaleString(locale, { minimumFractionDigits: digits, maximumFractionDigits: digits })
  }

  const resp = results.response;

  totals.push({
    label: "Earned Interest Amount",
    key: "totalEarnedInterest",
    value: numberToString(resp.TotalInterest)
  });

  if (resp.AccruedAndUnpaidInterest && resp.TotalInterestReceived) {
    totals.push({
      label: "Accrued and Unpaid Interest",
      key: "accruedAndUnpaidInterest",
      value: numberToString(resp.AccruedAndUnpaidInterest)
    });
  }

  if (resp.TotalInterestReceived) {
    totals.push({
      label: "Total Interest Received",
      key: "totalInterestReceived",
      value: numberToString(resp.TotalInterestReceived)
    });
  }

  if (resp.TotalCompoundBalanceTransferred) {
    totals.push({
      label: "Total Base Interest Transferred",
      key: "totalCompoundBalanceTransferred",
      value: numberToString(resp.TotalCompoundBalanceTransferred)
    });
  }

  const responseHasCommitmentChanges = results.inputs && results.inputs.commitment.some(c => c.change != 0);

  if (!responseHasCommitmentChanges && resp.PeriodCompoundedAllInRate != null) {
    totals.push({
      label: "Period Compounded All-In Rate",
      key: "periodCompoundedAllInRate",
      value: numberToString(resp.PeriodCompoundedAllInRate * 100, 7)
    });
  }

  if (resp.AnnualizedCompoundedAllInRate) {
    totals.push({
      label: "Annualized Compounded All-In Rate",
      key: "annualizedCompoundedAllInRate",
      value: numberToString(resp.AnnualizedCompoundedAllInRate * 100, 7)
    });
  }

  return totals; 
}

interface TableHeader {
  key: string;
  label: string;
  description?: string;
}

export function getResultTable(results: ResultsModel): [TableHeader[], string[][]];
export function getResultTable(results: ResultsModel, isXlsx: boolean): [TableHeader[], (string | Date | number)[][]];
export function getResultTable(results: ResultsModel, isXlsx?: boolean): [TableHeader[], (string | Date | number)[][]] {

  const rfr = lookupRFRvalue(results.inputs.RFR);

  const cur = rfr.cur
  const rateRounding = rfr.rounding
  const rfrResponseDateFormat = "YYYY-MM-DD[T]HH:mm:ss";
  const isCompoundInArrears = results.inputs.interestMethod === "CompoundInArrears";
  const isSimpleInArrears = results.inputs.interestMethod === "SimpleInArrears";
  const hasAdjustment = !(results.inputs.adjustment == null || results.inputs.adjustment == 0);
  const isNCCR = results.inputs.interestMethod === "NCCR";

  const hasInterestReceived = results.inputs.commitment.some(r => r.interestReceived);

  const isDisplay = isXlsx == undefined;
  const isCsv = isXlsx == false;

  const header: TableHeader[] = [
    { key: "notional_amount", label: "Notional Amount" },
    ...(isCompoundInArrears ? [{ key: "notional_amount_compounded", label: "Notional Amount (compounded)" }] : []),
    { key: "start_date", label: "Start Date" },
    { key: "end_date", label: "End Date" },
    ...(!isSimpleInArrears ? [{ key: "day_count", label: "Day Count" }] : []),
    ...(!isDisplay ? [{ key: "effective_base_rate", label: "Effective Base Rate %" }] : []),
    { key: "base_rate", label: "Base Rate %" },
    { key: "spread", label: "Spread %" },
    ...(hasAdjustment ? [{ key: "adjustment", label: "Adjustment %" }] : []),
    ...(isNCCR ? [
      { key: "effective_rfr", label: "Effective RFR", description: "Risk-free rate for the interest period" },
      { key: "compounding_factor", label: "Compounding Factor", description: "Applying the compounding effect to daily Effective RFR" },
      { key: "annualized_ccr", label: "Annualized CCR %", description: "Annualize the Compounding Factor, in order to apply Rounding criteria" },
      { key: "unannualized_ccr", label: "Unannualized CCR", description: "Compounding Factor for the reporting period (cumulative number of days)" },
      { key: "non_ccr", label: "Non-Cumulative (NCCR) %", description: "Difference in Unannualized CCR from the previous interest period, annualized" }
    ] : []),
    ...(isCompoundInArrears ? [{ key: "compounded_all_in_rate", label: "Annualized Compounded All-In Rate %" }] : []),
    { key: "earned_interest_from_base", label: `Earned Interest From Base Rate (${cur})` },
    { key: "earned_interest_from_spread", label: `Earned Interest From Spread (${cur})` },
    ...(hasAdjustment ? [{ key: "earned_interest_from_adjustment", label: `Earned Interest From Adjustment (${cur})` }] : []),
    { key: "earned_interest_total", label: `Earned Interest Total (${cur})` },
    { key: "earned_interest_balance", label: `Earned Interest Balance (${cur})` },
    ...(hasInterestReceived ? [{ key: "accrued_interest_balance", label: `Accrued Interest Balance (${cur})` }] : [])
  ];

  function formatNumberOutput(x: number, digits = 2): string | number {
    if (x == null) {
      return "";
    }
    if (isXlsx) {
      return x;
    } else if (isCsv) {
      return x.toString();
    } else {
      return x.toLocaleString(rfr.locale, { minimumFractionDigits: digits, maximumFractionDigits: digits });
    }
  }
  function formatDateOutput(x: moment.Moment, dateFormat: string): string | Date {
    if (isXlsx) {
      return x.local(true).toDate();
    } else {
      return x.format(dateFormat);
    }
  }
  const annualizedCCRRounding = results.inputs.rateRounding == 0 ? 7 : results.inputs.rateRounding;
  const data =
    results.response.AccrualResults.map<any[]>(a => [
      formatNumberOutput(a.NotionalAmount),
      ...(isCompoundInArrears ? [formatNumberOutput(a.NotionalAmountCompounded)] : []),
      formatDateOutput(utc(a.StartDate, rfrResponseDateFormat), rfr.dateFormat),
      formatDateOutput(utc(a.EndDate, rfrResponseDateFormat), rfr.dateFormat),
      ...(!isSimpleInArrears ? [formatNumberOutput(a.DayCount ?? 0, 0)] : []),
      ...(isDisplay ? [
        formatNumberOutput(a.BaseRate * 100, rateRounding) + (a.BaseRatePublished == null ? "" : " (" + formatNumberOutput(a.BaseRatePublished * 100, rateRounding) + ")")
      ] : [
        formatNumberOutput(a.BaseRate * 100),
        formatNumberOutput((a.BaseRatePublished == null ? a.BaseRate : a.BaseRatePublished) * 100)
      ]),
      formatNumberOutput(a.Spread * 100),
      ...(hasAdjustment ? [formatNumberOutput((a.Adjustment ?? 0) * 100)] : []),
      ...(isNCCR ? [
        formatNumberOutput(a.EffectiveRFR ?? 0, 7),
        formatNumberOutput(a.CompoundingFactor ?? 0, 7),
        formatNumberOutput((a.AnnualizedCCR ?? 0) * 100, annualizedCCRRounding),
        formatNumberOutput(a.UnannualizedCCR ?? 0, 7),
        formatNumberOutput((a.NonCCR ?? 0) * 100, 7),
      ] : []),
      ...(isCompoundInArrears ? [formatNumberOutput((a.AnnualizedCompoundedAllInRate ?? 0) * 100, 7)] : []),
      formatNumberOutput(a.EarnedInterestFromBaseRate),
      formatNumberOutput(a.EarnedInterestFromSpread),
      ...(hasAdjustment ? [formatNumberOutput(a.EarnedInterestFromAdjustment ?? 0)] : []),
      formatNumberOutput(a.EarnedInterest),
      formatNumberOutput(a.EarnedInterestRunningBalance),
      ...(hasInterestReceived ? [formatNumberOutput(a.AccruedInterestBalance ?? 0)] : [])
    ]);
  return [header, data];
}
