import { utc } from "moment";
import { InputModel, ValidationInputs, ValidationResults } from "../models/InputModel";
import { isMocked } from "../services/CalcService";
import { lookupRFRvalue } from "./AllInputOptions";
import { dataDateFormat, isBusinessDay, isValidDate } from "./DateUtils";
import { isTenorEnabled } from "./InputAvailability";

export function checkInvalidInput(inputs: InputModel, validationInputs: ValidationInputs): ValidationResults {
  function none(): ValidationResults {
    return { invalidInput: false };
  }

  if (inputs.interestMethod === "Term") {
    validationInputs.maxEndDate = utc({ year: 2099, month: 11, date: 31 }); // "12/31/2099"
  } else if (inputs.interestMethod === "TermCME") {
    validationInputs.minStartDate = validationInputs.minStartDateWithRateData;
    validationInputs.maxEndDate = utc({ year: 2099, month: 11, date: 31 }); // "12/31/2099"
  } else {
    validationInputs.minStartDate = validationInputs.minStartDateWithRateData;
    validationInputs.maxEndDate = validationInputs.maxEndDateWithRateData;
  }

  // effective dates must fall betseen start and end dates
  validationInputs.minEffectiveDate = utc(inputs.commitment[0].effectiveDate, dataDateFormat);
  validationInputs.minEffectiveDate.add(1, 'd');
  validationInputs.maxEffectiveDate = utc(inputs.endDate, dataDateFormat);
  validationInputs.maxEffectiveDate.subtract(1, 'd');

  if (!isMocked) {
    if (!validationInputs.minStartDate || !validationInputs.maxEndDateWithRateData || !validationInputs.maxEndDate) {
      return none(); // do not fail if initial range request did not returned yet
    }

    // set maxStartDate to one day before maxEndDate
    if (inputs.interestMethod === "TermCME") {
      validationInputs.maxStartDate = validationInputs.maxEndDateWithRateData.clone();
    } else {
      validationInputs.maxStartDate = validationInputs.maxEndDate.clone();
    }
    validationInputs.maxStartDate.subtract(1, 'd');
    // set minEndDate to one day after minStartDate
    validationInputs.minEndDate = validationInputs.minStartDate.clone();
    validationInputs.minEndDate.add(1, 'd');
  }

  const rfr = lookupRFRvalue(inputs.RFR);
  const isTerm = inputs.interestMethod === "Term" || inputs.interestMethod === "TermCME";

  function error(message?: string): ValidationResults {
    return { invalidInput: true, inputErrorMessage: message };
  }

  if (validationInputs.pendingValidationRequests > 0) {
    return none();
  }
  const commitmentDates = inputs.commitment.map(d => utc(d.effectiveDate, dataDateFormat));
  const startDate = commitmentDates[0];
  const endDate = utc(inputs.endDate, dataDateFormat);
  if (!startDate || !startDate.isValid()) {
    return error("Start Date is missing or invalid.");
  }
  if (!inputs.endDate || !endDate.isValid()) {
    return error("End Date is missing or invalid.");
  }
  if (startDate.isSameOrAfter(inputs.endDate)) {
    return error("Start Date must be earlier than End Date.");
  }
  if (!isValidDate(inputs, validationInputs, startDate)) {
    return error("Start Date must be a business day for compounding calculations.");
  }
  if (!isValidDate(inputs, validationInputs, endDate)) {
    return error("End Date must be a business day for compounding calculations.");
  }
  for (var i = 1; i < inputs.commitment.length; i++) {
    const effectiveDate = commitmentDates[i];
    if (!effectiveDate || !effectiveDate.isValid()) {
      return error("An Effective Date is missing or invalid.");
    }
    if (effectiveDate.isSameOrBefore(startDate)) {
      return error(`Effective Date ${effectiveDate.format(rfr.dateFormat)} must be later than Start Date.`);
    }
    const prevEffectiveDate = inputs.commitment[i - 1].effectiveDate;
    if (effectiveDate.isSameOrBefore(prevEffectiveDate)) {
      return error(`Effective Date ${effectiveDate.format(rfr.dateFormat)} must be later than previous Effective Date.`);
    }
    if (effectiveDate.isSameOrAfter(inputs.endDate)) {
      return error(`Effective Date ${effectiveDate.format(rfr.dateFormat)} must be earlier than End Date.`);
    }
    if (!isValidDate(inputs, validationInputs, effectiveDate)) {
      return error(`Effective Date ${effectiveDate.format(rfr.dateFormat)} must be a business day for compounding calculations.`);
    }
  }
  if (!isMocked && !isTerm && inputs.lockout >= validationInputs.businessDayCount) {
    return error(`Lockout value must be lower than the number of business days in date range. Set Lockout to be at most ${validationInputs.businessDayCount - 1}, or adjust Start or End Date.`);
  }
  let invalidInput =
    commitmentDates.some(r => !r.isValid())
    || !endDate.isValid()
    || inputs.selectedCountryForHolidays.length == 0;
  let inputErrorMessage;
  if (validationInputs.minStartDate && validationInputs.maxEndDate) { // do not check if initial range request did not returned yet
    const tooEarlyStartDate = startDate.isBefore(validationInputs.minStartDate);
    const tooLateEndDate = endDate.isAfter(validationInputs.maxEndDate);
    if (tooEarlyStartDate || tooLateEndDate) {
      invalidInput = true;
      const rfrName = rfr.name;
      //if (isTenorEnabled(inputs)) {
      //  rfrName += "-" + inputs.tenor.shortName;
      //}
      inputErrorMessage = `For ${rfrName} with Look Back ${inputs.lookBack} Lockout ${inputs.lockout}`;
      if (tooEarlyStartDate) {
        inputErrorMessage += ` minimum Start Date is ${validationInputs.minStartDate.format(rfr.dateFormat)}`;
        if (tooLateEndDate) {
          inputErrorMessage += " and";
        }
      }
      if (tooLateEndDate) {
        inputErrorMessage += ` maximum End Date is ${validationInputs.maxEndDate.format(rfr.dateFormat)}`;
      }
      inputErrorMessage += ".";
    }
    //else if (inputs.interestMethod === "TermCME" && startDate.isAfter(validationInputs.maxStartDate)) {
    //  const rfrName = rfr.name + "-" + inputs.tenor.shortName;
    //  inputErrorMessage = `For ${rfrName} with Look Back ${inputs.lookBack} maximum Start Date is ${validationInputs.maxStartDate.format(rfr.dateFormat)}.`
    //}
  }
  function warning(message: string): ValidationResults {
    return { invalidInput: false, inputWarningMessage: message }; 
  }
  if (invalidInput) {
    return error(inputErrorMessage);
  } else {
    if (inputs.interestMethod === "SimpleInArrears" || isTerm) {
      if (!isBusinessDay(validationInputs, startDate)) {
        return warning("Start Date is considered a non-business day as it does not have a published rate.");
      }
      if (!isBusinessDay(validationInputs, endDate)) {
        return warning("End Date is considered a non-business day as it does not have a published rate.");
      }
      for (var i = 1; i < inputs.commitment.length; i++) {
        const effectiveDate = commitmentDates[i];
        if (!isBusinessDay(validationInputs, effectiveDate)) {
          return warning(`Effective Date ${effectiveDate.format(rfr.dateFormat)} is considered a non-business day as it does not have a published rate.`);
        }
      }
    }
  }

  return none();
}
