import { UIRequestRange, UIRequest, UIDateRangeRequest, UIBusinessDayCountRequest, UIHolidayDatesRequest, Calendar, DayCountValue, InterestMethodValue, RFRValue } from './UIRequestModel';
import { Moment } from 'moment';
import { CalcResponse } from './ResponseModel';

export interface Rounding {
  value?: number;
  name: string;
}

export type FloorTypeName = "Base Rate" | "Base Rate Incl. Adj." | "All-In Rate" | "All-In Rate Incl. Adj."

export interface FloorType {
  name: FloorTypeName;
  shortName: string;
  allInRate: boolean;
  inclAdj: boolean;
}

export interface Index {
  name: RFRValue;
  cur: string;
  defaultCalendar: Calendar;
  defaultDayCount: DayCountValue;
  publisher: string;
  locale: string;
  dateFormat: string;
  rounding: number;
}


export interface InterestMethod {
  name: string;
  value: InterestMethodValue;
}

export interface Tenor {
  name: string;
  shortName: string;
  value: number;
}

export interface CommitmentChange {
  effectiveDate: string;
  spreadChange: number;
  spread: number;
  change: number;
  notional: number;
  balanceTransfer: boolean;
  interestReceived: boolean;
}

export interface DayCount {
  name: string;
  v1: DayCountValue;
  v2: number;
}

export interface ResponseView {
  notional_amount?: number;
  notional_amount_compounded?: number;
  start_date?: string;
  end_date?: string;
  day_count?: number;
  base_rate_published?: number;
  base_rate?: number;
  spread?: number;
  adjustment?: number;
  annualized_compounded_all_in_rate?: number;
  earned_interest_from_base?: number;
  earned_interest_from_spread?: number;
  earned_interest_from_adjustment?: number;
  earned_interest_total?: number;
  earned_interest_balance?: number;
  accrued_interest_balance?: number;
  effective_rfr?: number;
  compounding_factor?: number;
  annualized_ccr?: number;
  unannualized_ccr?: number;
  non_ccr?: number;
}

export interface InputModel {
  commitment: CommitmentChange[];
  endDate: string;
  lookBack: number;
  observationShift: boolean;
  lockout: number;
  selectedCountryForHolidays: Calendar[];
  interestMethod: InterestMethodValue;
  RFR: RFRValue;
  dayCount: DayCountValue;
  rateRounding: number;
  adjustment: number;
  termRate: number;
  floor: number | null;
  floorType: FloorTypeName | null;
  //tenor: string;
}

export function defaultInputModel(): InputModel {
  return {
    endDate: "10/31/2022",
    commitment: [
      {
        effectiveDate: "09/30/2022",
        spread: 0.5,
        spreadChange: 0,
        notional: 15000000,
        change: 0,
        balanceTransfer: false,
        interestReceived: false
      }
    ],
    lookBack: 5,
    observationShift: false,
    lockout: 0,
    selectedCountryForHolidays: ["USA"],
    interestMethod: "CompoundInArrears",
    RFR: "SOFR",
    dayCount: "ACT360",
    rateRounding: 0,
    adjustment: 0,
    termRate: 0,
    floor: null,
    floorType: null,
    //tenor: "1 Month"
  }
}

export interface ResultsModel { 
  response: CalcResponse;
  inputs: InputModel
}

export interface MessagesModel {
  apiErrorMessage?: string;
}

export interface ValidationInputs {
  pendingValidationRequests: number;
  minStartDate?: Moment;
  minStartDateWithRateData?: Moment;
  maxStartDate?: Moment;
  minEndDate?: Moment;
  maxEndDateWithRateData?: Moment;
  maxEndDate?: Moment;
  minEffectiveDate?: Moment;
  maxEffectiveDate?: Moment;
  mostRecentPublishedDate?: string;
  holidayDates: Moment[];
  businessDayCount: number;
}

export function emptyValidationInputs(): ValidationInputs {
  return {
    pendingValidationRequests: 0,
    holidayDates: [],
    businessDayCount: 0
  };
}

export interface ValidationResults {
  invalidInput: boolean;
  inputErrorMessage?: string;
  inputWarningMessage?: string;
}

